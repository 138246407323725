import classnames from "classnames";
import { Fragment, useState, useCallback, useMemo } from "react";

import { CoastFeaturedItemFragment, BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { CoastFeaturedLoadMore } from "./CoastFeaturedLoadMore.components";
import { CoastFeaturedItem } from "./CoastFeaturedItem.component";
import { Container, Heading } from "../../../../core/ui";
import "./CoastFeatured.style.scss";
import { AdPosition } from "../../../../core/page-blocks/Ad.component";

interface CoastFeaturedProps {
  sections: CoastFeaturedItemFragment[][];
  block: BlockFragment;
  title: string;
}

export const CoastFeatured = ({ title, sections, block }: CoastFeaturedProps) => {
  const [sectionsVisible, setSectionsVisible] = useState(1);

  const handleLoadMore = useCallback(() => {
    if (sectionsVisible < sections.length) {
      setSectionsVisible(v => v + 1);
    }
  }, [sections.length, sectionsVisible]);

  // set ads on the block
  if (!block.value.position) {
    block.value.position = 3309;
  }

  const coastHints = useMemo(() => getCoastHints(block.tags), [block.tags]);
  const addClass = classnames("component-coast-featured", coastHints);

  return sections.length ? (
    <Container className={addClass}>
      <Heading level={2}>{title}</Heading>

      <section className="coast-featured-items">
        {sections.slice(0, sectionsVisible).map((segment, segmentIdx) =>
          segment.map((item, itemIdx) => (
            <Fragment key={itemIdx}>
              <CoastFeaturedItem item={item} />
              {itemIdx === 4 ? <AdPosition block={block} batchId={`${segmentIdx + 1}`} /> : null}
            </Fragment>
          )),
        )}
      </section>

      <CoastFeaturedLoadMore
        handleLoadMore={handleLoadMore}
        sectionsVisible={sectionsVisible}
        sectionsTotal={sections.length}
      />
    </Container>
  ) : null;
};
