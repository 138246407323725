import { useMemo } from "react";
import { inject } from "mobx-react";

import { FauxButton, Button, ButtonKind } from "../../../../core/ui";

import { reverseRoute } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

interface CoastFeaturedLoadMoreProps {
  handleLoadMore: () => void;
  sectionsVisible: number;
  sectionsTotal: number;
  store?: Store;
}

export const CoastFeaturedLoadMore = inject("store")(
  ({ handleLoadMore, sectionsVisible, sectionsTotal, store }: CoastFeaturedLoadMoreProps) => {
    if (!store) {
      return null;
    }

    const articlesUrl = useMemo(() => reverseRoute(store.site, "articles") || "#", [store.site]);
    const photosUrl = useMemo(() => reverseRoute(store.site, "photos") || "#", [store.site]);
    const showsUrl = useMemo(() => reverseRoute(store.site, "shows") || "#", [store.site]);

    return (
      <div className="coast-featured-load-more">
        {sectionsVisible < sectionsTotal ? (
          <div className="component-load-more">
            <Button click={handleLoadMore} kind={ButtonKind.PRIMARY}>
              Load More
            </Button>
          </div>
        ) : (
          <div className="more-content-buttons">
            <div className="component-load-more">
              <FauxButton to={articlesUrl}>More Articles</FauxButton>
            </div>
            <div className="component-load-more">
              <FauxButton to={showsUrl}>More Shows</FauxButton>
            </div>
            <div className="component-load-more">
              <FauxButton to={photosUrl}>More Photos</FauxButton>
            </div>
          </div>
        )}
      </div>
    );
  },
);
