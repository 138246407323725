import { inject, observer } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import { Remote } from "../../../../core/components/remote/Remote.component";
import type { PageBlockInterface } from "../../../../core/page-blocks/Block.component";
import { getCoastFeaturedItems } from "@inferno/renderer-shared-core";
import { CoastFeatured } from "./CoastFeatured.component";
import { CoastFeaturedSkeleton } from "./CoastFeaturedSkeleton.component";

interface FeaturedLoaderProps extends PageBlockInterface {
  store?: Store;
}

export const CoastFeaturedLoader = inject("store")(
  observer(({ store, block }: FeaturedLoaderProps) => {
    if (!store) {
      return null;
    }

    return (
      <Remote
        loader={getCoastFeaturedItems}
        fallback={<CoastFeaturedSkeleton />}
        cacheKey="coast-featured"
        showLoading={true}
      >
        {({ data }) => <CoastFeatured title={block.value.title || "Featured"} sections={data || []} block={block} />}
      </Remote>
    );
  }),
);

export default CoastFeaturedLoader;
