import { Skeleton } from "../../../../core/ui";

export const CoastFeaturedSkeleton = ({ items = 9 }: { items?: number }) => {
  const results = [];
  for (let idx = 0; idx < items; idx++) {
    results.push(
      <div className="component-coast-featured-item" key={idx}>
        <figure className="item-thumb">
          <Skeleton pulse={true} type="image" name="card thumbnail" shape="16x9" />
        </figure>
      </div>,
    );
  }

  return (
    <section className="component-coast-featured">
      <section className="coast-featured-items">{results}</section>;
    </section>
  );
};
