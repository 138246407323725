import { inject } from "mobx-react";

import { ResponsiveImage } from "../../../../core/components/ResponsiveImage.component";
import type { CoastFeaturedItemFragment } from "@ihr-radioedit/inferno-webapi";
import { getCoastFeedImg, CoastFeedItemType } from "../../lib/coastFeed";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { zonedTimeFormat } from "@inferno/renderer-shared-core";

import { MagicLink } from "../../../../core/ui";
import { RESPONSIVE_IMAGE, reverseRoute } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

const categoryDisplay = {
  SHOW: "Show",
  PHOTO: "Blast from the past",
  ARTICLE: "Article",
};

const contentTypeToPageType = {
  SHOW: "shows_detail",
  PHOTO: "photos_detail",
  ARTICLE: "articles_detail",
};

interface CoastFeaturedItemProps {
  item: CoastFeaturedItemFragment;
  store?: Store;
}

export const CoastFeaturedItem = inject("store")(
  ({ item: { summary, slug, content_type, pub_start }, store }: CoastFeaturedItemProps) => {
    if (!store || !summary.image || !slug) {
      return null;
    }

    const { env } = store;

    const itemDate = zonedTimeFormat({
      date: pub_start.timestampMs,
      timezone: store.site.index.timeZone || env.DEFAULT_TIMEZONE,
      outputFormat: "MMM dd, yyyy",
    });

    const thumb = formatImage(summary.image, env.IMAGE_HOST);
    const { imgSrc, srcset } = getCoastFeedImg(thumb, CoastFeedItemType.SHOW, { quality: 80 });

    const itemUrl = reverseRoute(store.site, contentTypeToPageType[content_type], { slug }) || "#";

    return (
      <MagicLink to={itemUrl} context={slug} className="component-coast-featured-item">
        <figure>
          <ResponsiveImage
            alt={summary.title}
            src={imgSrc}
            srcset={srcset}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
          />

          <figcaption>
            <section className="thumb-meta">
              <span className="item-type">{categoryDisplay[content_type]}</span>
              &nbsp;|&nbsp;
              <time className="item-published">{itemDate}</time>
            </section>

            <span className="item-title">{summary.title}</span>
          </figcaption>
        </figure>
      </MagicLink>
    );
  },
);
